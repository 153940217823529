import { Discount, Plan } from '@/pages/Shopping/interfaces/IShopping';

function findDiscountByType(typeDiscount: string, plan: Plan) {
  if (!plan || !plan.discounts.length) return undefined;

  return plan.discounts.find((discountData: Discount) => discountData.code === typeDiscount);
}

function calculateDiscountPrice(totalPrice: number, discount: Discount) {
  const discountValue = totalPrice * (discount.percent / 100);

  if (discountValue < discount.max_value) {
    return totalPrice - discountValue;
  }

  return totalPrice - discount.max_value;
}

export function getPriceDiscount(typeDiscount: string, plan: Plan) {
  if (!plan || !typeDiscount) return 0;

  const totalPrice = plan.planPrice;

  const discount = findDiscountByType(typeDiscount, plan);

  if (!discount) return totalPrice;

  let discountPrice = 0;

  if (discount.percent) {
    discountPrice = calculateDiscountPrice(totalPrice, discount);
  }

  if (discount.value) {
    discountPrice = totalPrice - discount.value;
  }

  return discountPrice;
}
