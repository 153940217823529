






















import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import { TheMask } from 'vue-the-mask';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import { PixForm } from '../../interfaces/IForms';

@Component({
  components: {
    TheMask,
    FeedbackRequiredField
  }
})
export default class FormPix extends Vue {
  @Prop({ required: true }) data!: PixForm;

  public hasCpf = false;

  @Watch('data.cpf')
  cpfIsEmpty() {
    this.hasCpf = this.data.cpf === '';
  }
}
