















import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#5E2D7B';

@Component
export default class CreditCard extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '16' }) width!: string;
  @Prop({ default: '12' }) height!: string;
}
