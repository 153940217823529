


























import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#5E2D7B';

@Component
export default class Billet extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '11' }) width!: string;
  @Prop({ default: '15' }) height!: string;
}
