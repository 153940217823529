

























































































































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import { TheMask } from 'vue-the-mask';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import Information from '@/assets/icons/Information.vue';

import { CreditCartForm } from '../../interfaces/IForms';

import { Plan } from '../../interfaces/IShopping';

interface Installments {
  value: number;
  installments: number;
}

@Component({
  components: {
    TheMask,
    FeedbackRequiredField,
    Information
  }
})
export default class FormCreditCart extends Vue {
  @Prop({ required: true }) data!: CreditCartForm;
  @Prop({ required: true }) plan!: Plan;
  @Prop({ required: true }) amount!: number;

  public hasCardNumber = false;
  public hasHolderName = false;
  public hasCardExpiration = false;
  public hasCardCvv = false;
  public hasCpf = false;

  @Watch('data.card_number')
  cardNumberIsEmpty() {
    this.hasCardNumber = this.data.card_number === '';
  }

  @Watch('data.holder_name')
  holderNameIsEmpty() {
    this.hasHolderName = this.data.holder_name === '';
  }

  @Watch('data.card_expiration')
  cardExpirationIsEmpty() {
    this.hasCardExpiration = this.data.card_expiration === '';
  }
  @Watch('data.card_cvv')
  cardCvvIsEmpty() {
    this.hasCardCvv = this.data.card_cvv === '';
  }

  @Watch('data.cpf')
  cpfIsEmpty() {
    this.hasCpf = this.data.cpf === '';
  }

  get generateInstallments() {
    const installments: Installments[] = [];

    for (let index = 0; index < this.plan.planInstallments; index += 1) {
      installments.push({
        value: this.amount / (index + 1),
        installments: index + 1
      });
    }

    return installments;
  }
}
