









































































































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import { format } from 'date-fns';

import { Plan, Discount, PlanProduct } from '../../interfaces/IShopping';

import {
  INTERVAL_MONTHLY,
  LIST_INTERVAL,
  METHOD_PAYMENT,
  METHOD_PAYMENT_TITLE,
  TYPE_DISCOUNT,
  PHYSICAL_PRODUCT_ID
} from '../../constants/index';

import ActiveModal from '@/share/Util/ActiveModal';
import { getPriceDiscount } from '@/share/Util/Price/index';

import PlansService from '@/services/Plans/PlansService';

interface HandlePriceDiscount {
  price: number;
  discount?: string;
}

@Component({})
export default class PurchaseSummary extends Vue {
  @Prop({ required: true }) listTypePlans!: Plan[];
  @Prop({ required: true }) planIdStartActive!: number;
  @Prop({ default: false }) isRenew!: boolean;

  private choicePlanIdCard: number = this.planIdStartActive;
  private intervalSelected = null as null | number;
  private intervals = [] as typeof LIST_INTERVAL;
  private TYPE_DISCOUNT = TYPE_DISCOUNT;
  private discountPlan: Discount | null = null;
  private currentPlanSubtitle = '';
  private planProducts: PlanProduct[] | [] = [];
  private isLoadingPlanProduct = true;

  private setModal = new ActiveModal();
  private PlansService = new PlansService();

  get currentDate() {
    return format(new Date(), 'dd/MM/yyyy');
  }

  get planExpiration() {
    const planEndDate = this.$store.getters.profile?.plan?.end_date;

    return format(new Date(planEndDate), 'dd/MM/yyyy');
  }

  get currentPlan() {
    if (!this.listTypePlans || !this.listTypePlans.length) return null;

    return this.listTypePlans.find((plan: Plan) => plan.planId === this.choicePlanIdCard);
  }

  get methods() {
    return METHOD_PAYMENT_TITLE[this.choiceIndexMenu];
  }

  get pricePlanAndDiscount() {
    if (!this.currentPlan) return null;

    const discountMethods = this.handlePriceDiscount(this.discountPlan, this.currentPlan.planPrice);
    const discountUser = this.handlePriceDiscount(this.discountUser, discountMethods.price);
    const priceFinal = discountUser.price;

    return {
      price: this.currentPlan.planPrice,
      discountMethods: discountMethods.discount,
      discountUser: discountUser.discount,
      priceFinal
    };
  }

  get choiceIndexMenu() {
    return this.$store.getters.choiceIndexMenu;
  }

  get discountUser(): Discount | null {
    return this.$store.getters.discountUser;
  }

  get isPhysicalProduct() {
    if (!this.planProducts.length) return false;

    const physicalProductFoud = this.planProducts.find(
      (product: PlanProduct) => product.productId === PHYSICAL_PRODUCT_ID
    );

    return !!physicalProductFoud;
  }

  get showIntervalInfo() {
    return !this.isLoadingPlanProduct && !this.isPhysicalProduct;
  }

  @Watch('currentPlan', { deep: true, immediate: true })
  handleCurrentPlan() {
    this.setCurrentPlanSubtitle();
    this.setInterval();
    this.setDiscountPlan();
    this.getProductsByPlan();
  }

  @Watch('pricePlanAndDiscount', { immediate: true, deep: true })
  getFinalPrice() {
    if (!this.pricePlanAndDiscount) return;

    this.$store.commit('setFinalPrice', this.pricePlanAndDiscount.priceFinal);
  }

  @Watch('listTypePlans', { immediate: true })
  getTypeFromExistingLists() {
    if (!this.listTypePlans || !this.listTypePlans.length) return;

    const filteredInterval = LIST_INTERVAL.filter((interval) => this.listTypePlans.some((plan) => plan.planIntervalCount === interval.value));

    if (filteredInterval && filteredInterval.length) {
      this.intervals = filteredInterval;
    }
  }

  @Watch('intervalSelected')
  handleClickChoicePlan() {
    const planSelected = this.listTypePlans.find(
      (planItem: Plan) => planItem.planIntervalCount === this.intervalSelected
    );

    if (!planSelected) return;

    this.choicePlanIdCard = planSelected.planId;

    this.$emit('plan-active', planSelected);
  }

  @Watch('choiceIndexMenu', {
    immediate: true
  })
  setDiscountPlan() {
    if (!this.currentPlan) return;

    const type = METHOD_PAYMENT[this.choiceIndexMenu];

    const foundDiscountPrice = this.currentPlan.discounts.find(
      (discount: Discount) => discount.code === type
    );

    this.discountPlan = foundDiscountPrice || null;
  }

  async getProductsByPlan() {
    if (!this.currentPlan?.planId) {
      this.isLoadingPlanProduct = false;
      return;
    }

    try {
      this.isLoadingPlanProduct = true;

      const products = await this.PlansService.getPlanProducts(this.currentPlan?.planId);

      if (products && products.length) {
        this.planProducts = products;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingPlanProduct = false;
    }
  }

  setCurrentPlanSubtitle() {
    if (!this.currentPlan) return;

    if (this.currentPlan.planIntervalCount === INTERVAL_MONTHLY) {
      this.currentPlanSubtitle = `Acesso por ${INTERVAL_MONTHLY} mês`;
    }

    this.currentPlanSubtitle = `Acesso por ${this.currentPlan.planIntervalCount} meses`;
  }

  setInterval() {
    if (!this.intervalSelected && this.currentPlan) {
      const intervalFound = LIST_INTERVAL.find(
        (interval) => interval.value === this.currentPlan?.planIntervalCount
      );

      if (intervalFound) {
        this.intervalSelected = intervalFound.value;
      }
    }
  }

  handlePriceDiscount(discount: Discount | null, price: number): HandlePriceDiscount {
    if (!discount) {
      return {
        price,
        discount: '0'
      };
    }

    let priceFinal = 0;

    if (discount.percent) {
      priceFinal = price * (discount.percent / 100) > discount.max_value
        ? price - discount.max_value
        : price - price * (discount.percent / 100);
    }

    if (discount.value) {
      priceFinal = price - discount.value;
    }

    return {
      price: Number(priceFinal) || 0,
      discount: `${price - priceFinal}` || '0'
    };
  }

  getPriceDiscount(typeDiscount: string) {
    if (!this.currentPlan || !typeDiscount) return 0;

    return getPriceDiscount(typeDiscount, this.currentPlan);
  }

  handleClickModalDescriptive() {
    this.setModal.activeModal('ModalDescriptionsPlan');
  }
}
